import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/Layout"
// import SEO from "../components/utility/SEO"
import PageContent from "../components/common/PageContent"
// markup
const NotFoundPage = ({ location }) => {
  const path = location.pathname ? location.pathname : ""

  return (
    <Layout>
      {/* <SEO title="404: Not found" /> */}
      <title>Not Found</title>
      <PageContent>
        <h1>Page not found</h1>
        <p>
          <p>Sorry, there&apos;s nothing here{path ? ` at ${path}` : "..."}</p>
          <Link to="/">Head home &rarr;</Link>
        </p>
      </PageContent>
    </Layout>
  )
}

export default NotFoundPage
